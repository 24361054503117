import React, { FC } from "react";
import { useVisionData } from "../../../../hooks/useVisionData";
import { RetailMetrics } from "@technis/shared";
import moment from "moment";

export const Card: FC<{
  zoneId: number;
  organizationId: number;
  installationId: number;
  title: string;
  token?: string;
}> = ({ zoneId, title, installationId, organizationId }) => {
  const waitingTime = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.WAITING_TIME });
  const duration = moment.duration(waitingTime || 0, "seconds");
  const hours = duration.hours();
  let minutes = duration.minutes();
  const seconds = duration.seconds();

  if (seconds >= 30) {
    minutes += 1;
  }

  const inside = useVisionData({ installationId, organizationId, zoneId, metric: RetailMetrics.INSIDE });

  return (
    <div className="marseille-page__card">
      <h3 className="marseille-page__card-title">{title}</h3>
      <div className="marseille-page__card-container">
        <div>
          <p className="marseille-page__card-value">
            {inside}
            <span> personnes</span>
          </p>
          <p className="marseille-page__card-metric">Personnes dans la file</p>
        </div>
        <div>
          <p className="marseille-page__card-value">
            {!!hours && (
              <>
                {hours}
                <span>hr</span>
              </>
            )}
            {!!minutes && (
              <>
                {minutes}
                <span>min</span>
              </>
            )}
            {!hours && !minutes && (
              <>
                - <span>min</span>
              </>
            )}
          </p>
          <p className="marseille-page__card-metric">Temps d’attente moyen</p>
        </div>
      </div>
    </div>
  );
};
